export const REPORTING_SELECT_ID = 'REPORTING_SELECT_ID';

/* NAVIGATION */
export const NAVIGATE_TO_CREATE_FEATURE = 'NAVIGATE_TO_CREATE_FEATURE';
export const NAVIGATE_TO_CREATE_API_KEY = 'NAVIGATE_TO_CREATE_API_KEY';
export const CREATE_API_TOKEN_BUTTON = 'CREATE_API_TOKEN_BUTTON';

/* CREATE FEATURE */
export const CF_NAME_ID = 'CF_NAME_ID';
export const CF_TYPE_ID = 'CF_TYPE_ID';
export const CF_DESC_ID = 'CF_DESC_ID';
export const CF_CREATE_BTN_ID = 'CF_CREATE_BTN_ID';

/* LOGIN */
export const LOGIN_EMAIL_ID = 'LOGIN_EMAIL_ID';
export const LOGIN_BUTTON = 'LOGIN_BUTTON';
export const LOGIN_PASSWORD_ID = 'LOGIN_PASSWORD_ID';

/* STRATEGY */
export const ADD_NEW_STRATEGY_ID = 'ADD_NEW_STRATEGY_ID';
export const ADD_NEW_STRATEGY_CARD_BUTTON_ID =
    'ADD_NEW_STRATEGY_CARD_BUTTON_ID';
export const ROLLOUT_SLIDER_ID = 'ROLLOUT_SLIDER_ID';
export const ADD_NEW_STRATEGY_SAVE_ID = 'ADD_NEW_STRATEGY_SAVE_ID';
export const DIALOGUE_CONFIRM_ID = 'DIALOGUE_CONFIRM_ID';
export const ADD_CONSTRAINT_ID = 'ADD_CONSTRAINT_ID';
export const CONSTRAINT_AUTOCOMPLETE_ID = 'CONSTRAINT_AUTOCOMPLETE_ID';
export const STRATEGY_ACCORDION_ID = 'STRATEGY_ACCORDION_ID';
export const FLEXIBLE_STRATEGY_STICKINESS_ID =
    'FLEXIBLE_STRATEGY_STICKINESS_ID';
export const FLEXIBLE_STRATEGY_GROUP_ID = 'FLEXIBLE_STRATEGY_GROUP_ID';
export const SELECT_ITEM_ID = 'SELECT_ITEM_ID';
export const UPDATE_STRATEGY_BUTTON_ID = 'UPDATE_STRATEGY_BUTTON_ID';
export const DELETE_STRATEGY_ID = 'DELETE_STRATEGY_ID';
export const STRATEGY_INPUT_LIST = 'STRATEGY_INPUT_LIST';
export const ADD_TO_STRATEGY_INPUT_LIST = 'ADD_TO_STRATEGY_INPUT_LIST';

/* SPLASH */
export const CLOSE_SPLASH = 'CLOSE_SPLASH';
