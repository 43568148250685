import api from './api';
import { dispatchError } from '../util';
import { MUTE_ERROR } from '../error/actions';
const debug = require('debug')('unleash:feature-actions');

export const ADD_FEATURE_TOGGLE = 'ADD_FEATURE_TOGGLE';
export const COPY_FEATURE_TOGGLE = 'COPY_FEATURE_TOGGLE';
export const REMOVE_FEATURE_TOGGLE = 'REMOVE_FEATURE_TOGGLE';
export const UPDATE_FEATURE_TOGGLE = 'UPDATE_FEATURE_TOGGLE';
export const TOGGLE_FEATURE_TOGGLE = 'TOGGLE_FEATURE_TOGGLE';
export const START_FETCH_FEATURE_TOGGLES = 'START_FETCH_FEATURE_TOGGLES';
export const START_UPDATE_FEATURE_TOGGLE = 'START_UPDATE_FEATURE_TOGGLE';
export const START_CREATE_FEATURE_TOGGLE = 'START_CREATE_FEATURE_TOGGLE';
export const FETCH_FEATURE_TOGGLES_SUCCESS = 'FETCH_FEATURE_TOGGLES_SUCCESS';
export const START_REMOVE_FEATURE_TOGGLE = 'START_REMOVE_FEATURE_TOGGLE';
export const RECEIVE_FEATURE_TOGGLES = 'RECEIVE_FEATURE_TOGGLES';
export const ERROR_FETCH_FEATURE_TOGGLES = 'ERROR_FETCH_FEATURE_TOGGLES';
export const ERROR_CREATING_FEATURE_TOGGLE = 'ERROR_CREATING_FEATURE_TOGGLE';
export const ERROR_UPDATE_FEATURE_TOGGLE = 'ERROR_UPDATE_FEATURE_TOGGLE';
export const ERROR_REMOVE_FEATURE_TOGGLE = 'ERROR_REMOVE_FEATURE_TOGGLE';
export const UPDATE_FEATURE_TOGGLE_STRATEGIES =
    'UPDATE_FEATURE_TOGGLE_STRATEGIES';
export const FETCH_FEATURE_TOGGLE_ERROR = 'FETCH_FEATURE_TOGGLE_ERROR';
export const RESET_LOADING = 'RESET_LOADING';

export const RECEIVE_FEATURE_TOGGLE = 'RECEIVE_FEATURE_TOGGLE';
export const START_FETCH_FEATURE_TOGGLE = 'START_FETCH_FEATURE_TOGGLE';
export const ERROR_FETCH_FEATURE_TOGGLE = 'START_FETCH_FEATURE_TOGGLE';

export function toggleFeature(enable, name) {
    debug('Toggle feature toggle ', name);
    return dispatch => {
        dispatch(requestToggleFeatureToggle(enable, name));
    };
}

export function setStale(stale, name) {
    debug('Set stale property on feature toggle ', name);
    return dispatch => {
        dispatch(requestSetStaleFeatureToggle(stale, name));
    };
}

export function editFeatureToggle(featureToggle) {
    debug('Update feature toggle ', featureToggle);
    return dispatch => {
        dispatch(requestUpdateFeatureToggle(featureToggle));
    };
}

function receiveFeatureToggles(json) {
    debug('reviced feature toggles', json);
    return {
        type: RECEIVE_FEATURE_TOGGLES,
        featureToggles: json.features.map(features => features),
        receivedAt: Date.now(),
    };
}

function receiveFeatureToggle(featureToggle) {
    debug('reviced feature toggle', featureToggle);
    return {
        type: RECEIVE_FEATURE_TOGGLE,
        featureToggle,
        receivedAt: Date.now(),
    };
}

export function fetchFeatureToggles() {
    debug('Start fetching feature toggles');
    return dispatch => {
        dispatch({ type: START_FETCH_FEATURE_TOGGLES });

        return api
            .fetchAll()
            .then(json => {
                dispatch({ type: FETCH_FEATURE_TOGGLES_SUCCESS });
                dispatch(receiveFeatureToggles(json));
            })
            .catch(() => {
                dispatch({ type: FETCH_FEATURE_TOGGLE_ERROR });
                dispatchError(dispatch, ERROR_FETCH_FEATURE_TOGGLES);
            });
    };
}

export function fetchFeatureToggle(name) {
    debug('Start fetching feature toggles');

    return dispatch => {
        dispatch({ type: START_FETCH_FEATURE_TOGGLE });

        return api
            .fetchFeatureToggle(name)
            .then(json => dispatch(receiveFeatureToggle(json)))
            .catch(dispatchError(dispatch, ERROR_FETCH_FEATURE_TOGGLE));
    };
}

export function createFeatureToggles(featureToggle) {
    return dispatch => {
        dispatch({ type: START_CREATE_FEATURE_TOGGLE });

        return api
            .create(featureToggle)
            .then(res => res.json())
            .then(createdFeature => {
                dispatch({
                    type: ADD_FEATURE_TOGGLE,
                    featureToggle: createdFeature,
                });
            })
            .catch(e => {
                dispatchError(dispatch, ERROR_CREATING_FEATURE_TOGGLE);
                throw e;
            });
    };
}

export function requestToggleFeatureToggle(enable, name) {
    return dispatch => {
        dispatch({ type: START_UPDATE_FEATURE_TOGGLE });

        return api
            .toggle(enable, name)
            .then(() => dispatch({ type: TOGGLE_FEATURE_TOGGLE, name }))
            .catch(dispatchError(dispatch, ERROR_UPDATE_FEATURE_TOGGLE));
    };
}

export function requestSetStaleFeatureToggle(stale, name) {
    return dispatch => {
        dispatch({ type: START_UPDATE_FEATURE_TOGGLE });

        return api
            .setStale(stale, name)
            .then(featureToggle => {
                const info = `${name} marked as ${stale ? 'Stale' : 'Active'}.`;
                setTimeout(
                    () => dispatch({ type: MUTE_ERROR, error: info }),
                    1000
                );
                dispatch({ type: UPDATE_FEATURE_TOGGLE, featureToggle, info });
            })
            .catch(dispatchError(dispatch, ERROR_UPDATE_FEATURE_TOGGLE));
    };
}

export function requestUpdateFeatureToggle(featureToggle) {
    return dispatch => {
        dispatch({ type: START_UPDATE_FEATURE_TOGGLE });

        return api
            .update(featureToggle)
            .then(() => {
                const info = `${featureToggle.name} successfully updated!`;
                setTimeout(
                    () => dispatch({ type: MUTE_ERROR, error: info }),
                    1000
                );
                dispatch({ type: UPDATE_FEATURE_TOGGLE, featureToggle, info });
            })
            .catch(dispatchError(dispatch, ERROR_UPDATE_FEATURE_TOGGLE));
    };
}

export function requestUpdateFeatureToggleStrategies(
    featureToggle,
    newStrategies
) {
    return dispatch => {
        featureToggle.strategies = newStrategies;
        dispatch({ type: START_UPDATE_FEATURE_TOGGLE });

        return api
            .update(featureToggle)
            .then(() => {
                const info = `${featureToggle.name} successfully updated!`;
                setTimeout(
                    () => dispatch({ type: MUTE_ERROR, error: info }),
                    1000
                );
                return dispatch({
                    type: UPDATE_FEATURE_TOGGLE_STRATEGIES,
                    featureToggle,
                    info,
                });
            })
            .catch(dispatchError(dispatch, ERROR_UPDATE_FEATURE_TOGGLE));
    };
}

export function requestUpdateFeatureToggleVariants(featureToggle, newVariants) {
    return dispatch => {
        const newFeature = { ...featureToggle };
        newFeature.variants = newVariants;
        dispatch({ type: START_UPDATE_FEATURE_TOGGLE });

        return api
            .update(newFeature)
            .then(() => {
                const info = `${newFeature.name} successfully updated!`;
                setTimeout(
                    () => dispatch({ type: MUTE_ERROR, error: info }),
                    1000
                );
                return dispatch({
                    type: UPDATE_FEATURE_TOGGLE_STRATEGIES,
                    featureToggle: newFeature,
                    info,
                });
            })
            .catch(e => {
                dispatchError(dispatch, ERROR_UPDATE_FEATURE_TOGGLE);
                throw e;
            });
    };
}

export function removeFeatureToggle(featureToggleName) {
    return dispatch => {
        dispatch({ type: START_REMOVE_FEATURE_TOGGLE });

        return api
            .remove(featureToggleName)
            .then(() =>
                dispatch({ type: REMOVE_FEATURE_TOGGLE, featureToggleName })
            )
            .catch(dispatchError(dispatch, ERROR_REMOVE_FEATURE_TOGGLE));
    };
}

export function validateName(featureToggleName) {
    return api.validate({ name: featureToggleName });
}
