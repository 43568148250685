import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    paperContainer: {
        borderRadius: '10px',
        boxShadow: 'none',
    },
    container: {
        display: 'flex',
    },
}));
