/* SORT TYPES */
export const NAME = 'name';
export const LAST_SEEN = 'lastSeen';
export const CREATED = 'created';
export const EXPIRED = 'expired';
export const STATUS = 'status';
export const REPORT = 'report';

/* DAYS */
export const FOURTYDAYS = 40;
export const SEVENDAYS = 7;
